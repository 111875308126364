/* eslint-disable no-unused-vars */
import tim from "tim";

const groupModules = {
  state: {
    groupList: [],
    currentMemberList: [],
    createGroupModelVisible: false,
    groupId: "",
  },
  getters: {
    groupId: (state) => state.groupId,
    hasGroupList: (state) => state.groupList.length > 0,
    hasGroupID: (state) => {
      return (groupId) => {
        console.log(
          "state.groupList.some(item => item.groupID == groupId)",
          state.groupList.filter((item) => item.groupID == groupId)
        );
        return state.groupList.some((item) => item.groupID == groupId);
      };
    },
  },
  mutations: {
    setGroupId(state, groupId) {
      state.groupId = groupId;
    },
    updateGroupList(state, groupList) {
      state.groupList = groupList;
    },
    updateCreateGroupModelVisible(state, visible) {
      state.createGroupModelVisible = visible;
    },
    updateCurrentMemberList(state, memberList) {
      state.currentMemberList = [...state.currentMemberList, ...memberList];
    },
    deleteGroupMemeber(state, userID) {
      state.currentMemberList = state.currentMemberList.filter(
        (member) => member.userID !== userID
      );
    },
    deleteGroupMemberList(state, userIDList) {
      state.currentMemberList = state.currentMemberList.filter(
        (member) => !userIDList.includes(member.userID)
      );
    },
    resetCurrentMemberList(state) {
      state.currentMemberList = [];
    },
    reset(state) {
      Object.assign(state, {
        groupList: [],
        currentMemberList: [],
        createGroupModelVisible: false,
        groupId: "",
      });
    },
  },
  actions: {
    updateGroupList({ commit, dispatch, getters }, groupList) {
      commit("updateGroupList", groupList);
      // if (
      //   getters.isSDKReady &&
      //   getters.toAccount !== getters.groupId &&
      //   getters.hasGroupID(getters.groupId)
      // ) {
      //   return dispatch("checkoutConversation", `GROUP${getters.groupId}`);
      // }
    },
    getGroupMemberList(context, groupID) {
      return tim
        .getGroupMemberList({
          groupID: groupID,
          offset: context.state.currentMemberList.length,
          count: 30,
        })
        .then((imResponse) => {
          context.commit("updateCurrentMemberList", imResponse.data.memberList);
          return imResponse;
        });
    },
    setGroupId(context, groupId) {
      context.commit("setGroupId", groupId);
    },
  },
};

export default groupModules;
