import Vue from "vue";
import VueRouter from "vue-router";
import { Dialog } from "vant";
import { getStrs } from "@/utils/url/getStrs";
import store from "@/store";
import { isWxMini } from "@/utils/weixin/wxEnv.js";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/Home/index.vue"),
    meta: { title: "妙手医生医疗门诊部互联网医院", keepAlive: true },
  },
  {
    path: "/shop",
    name: "Shop",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/Shop/index.vue"),
    meta: {
      title: "妙手医生医疗门诊部互联网医院",
      needLogin: true,
      keepAlive: true,
    },
  },
  {
    path: "/my",
    name: "My",
    component: () =>
      import(/* webpackChunkName: "home" */ "@/views/My/index.vue"),
    meta: { title: "妙手医生门诊部" },
  },
  {
    path: "/records", //健康档案-列表
    name: "healthRecords",
    component: () =>
      import(
        /* webpackChunkName: "health_records" */ "@/views/Records/index.vue"
      ),
    meta: { title: "就诊人管理" },
  },
  {
    path: "/add_record/:recordId?", //健康档案-列表
    name: "add_record",
    component: () =>
      import(
        /* webpackChunkName: "health_records" */ "@/views/Records_add/index.vue"
      ),
    meta: { title: "" },
  },
  {
    path: "/prescriptions",
    name: "prescription",
    component: () =>
      import(
        /* webpackChunkName: "prescription" */ "@/views/Prescriptions/index.vue"
      ),
    meta: { title: "我的处方", needLogin: true, keepAlive: true },
  },
  {
    path: "/prescriptions_detail",
    name: "prescriptions_detail",
    component: () =>
      import(
        /* webpackChunkName: "prescriptionDetail" */ "@/views/PrescriptionDetail/index.vue"
      ),
    meta: { title: "处方详情", needLogin: true, keepAlive: true },
  },
  {
    path: "/iquiry_list",
    name: "iquiry_list",
    component: () =>
      import(
        /* webpackChunkName: "iquiry_list" */ "@/views/IquiryList/index.vue"
      ),
    meta: { title: "我的问诊", needLogin: true },
  },
  {
    path: "/iquiry_summary",
    name: "iquiry_summary",
    component: () =>
      import(
        /* webpackChunkName: "iquiry_summary" */ "@/views/IquirySummary/index.vue"
      ),
    meta: { title: "问诊小结", needLogin: true },
  },
  {
    path: "/iquiry_summary_check",
    name: "iquiry_summary_check",
    component: () =>
      import(
        /* webpackChunkName: "iquiry_summary" */ "@/views/IquirySummaryCheck/index.vue"
      ),
    meta: { title: "问诊小结", needLogin: false },
  },
  {
    path: "/iquiry_protocol",
    name: "iquiry_protocol",
    component: () =>
      import(
        /* webpackChunkName: "iquiry_protocol" */ "@/views/IquiryProtocol/index.vue"
      ),
    meta: { title: "知情协议", needLogin: true },
  },
  {
    path: "/iquiry_step1",
    name: "iquiry_step1",
    component: () =>
      import(
        /* webpackChunkName: "iquiry_register1" */ "@/views/IquiryRegister/firstStep.vue"
      ),
    meta: { title: "图文咨询", needLogin: true, keepAlive: true },
  },
  {
    path: "/face_verify",
    name: "face_verify",
    component: () => import("@/views/IquiryRegister/faceVerify.vue"),
    meta: { title: "人脸实名认证", needLogin: true },
  },
  {
    path: "/chat/:groupId/:orderId",
    name: "chat",
    component: () =>
      import(/* webpackChunkName: "chat" */ "@/views/Chat/index.vue"),
    meta: { title: "问诊详情", needLogin: true },
  },
  {
    path: "/video_room/:roomId",
    name: "video_room",
    component: () =>
      import(/* webpackChunkName: "doctor" */ "@/views/Chat/VideoRoom.vue"),
    meta: { title: "视频问诊" },
  },
  {
    path: "/chat_detail/:groupId/:orderId",
    name: "chat_detail",
    component: () =>
      import(/* webpackChunkName: "chat" */ "@/views/ChatDetail/index.vue"),
    meta: { title: "问诊详情", needLogin: true },
  },
  {
    path: "/chat_detail_check/",
    name: "chat_detail_check",
    component: () =>
      import(
        /* webpackChunkName: "chat_detail_check" */ "@/views/ChatDetailCheck/index.vue"
      ),
    meta: { title: "问诊详情", needLogin: false },
  },
  {
    path: "/evaluate",
    name: "Evaluate",
    component: () =>
      import(/* webpackChunkName: "evaluate" */ "@/views/Evaluate/index.vue"),
    meta: { title: "订单评价", needLogin: true },
  },
  {
    path: "/doctor_index/:doctorId",
    name: "doctorIndex",
    component: () =>
      import(/* webpackChunkName: "doctor" */ "@/views/DoctorIndex/index.vue"),
    meta: { title: "医生详情" },
  },
  {
    path: "/doctor_list",
    name: "doctorList",
    component: () =>
      import(/* webpackChunkName: "doctor" */ "@/views/DoctorList/index.vue"),
    meta: { title: "医生列表" },
  },
  {
    path: "/cashier",
    name: "Cashier",
    component: () =>
      import(/* webpackChunkName: "cashier" */ "@/views/Cashier/index.vue"),
    meta: { title: "收银台" },
  },
  {
    path: "/announcement",
    name: "Announcement",
    component: () =>
      import(
        /* webpackChunkName: "Announcement" */ "@/views/Announcement/index.vue"
      ),
    meta: { title: "公告列表", keepAlive: true },
  },
  {
    path: "/announcementDetail",
    name: "AnnouncementDetail",
    component: () =>
      import(
        /* webpackChunkName: "Announcement" */ "@/views/AnnouncementDetail/index.vue"
      ),
    meta: { title: "公告详情" },
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/Login/index.vue"),
    meta: { title: "登录" },
  },
  {
    path: "/questions",
    name: "Questions",
    component: () =>
      import(/* webpackChunkName: "questions" */ "@/views/Questions/index.vue"),
    meta: { title: "问答" },
  },
  {
    path: "/article",
    name: "Article",
    component: () =>
      import(/* webpackChunkName: "Article" */ "@/views/Article/index.vue"),
    meta: { title: "文章" },
  },
  {
    path: "/video",
    name: "Video",
    component: () =>
      import(/* webpackChunkName: "Video" */ "@/views/Video/index.vue"),
    meta: { title: "视频" },
  },
  {
    path: "/order_list",
    name: "order_list",
    component: () =>
      import(/* webpackChunkName: "order_list" */ "@/views/Order/index.vue"),
    meta: { title: "我的订单", needLogin: true, keepAlive: true },
  },
  {
    path: "/order_detail/:orderId",
    name: "order_detail",
    component: () =>
      import(
        /* webpackChunkName: "order_detail" */ "@/views/Order/orderDetail.vue"
      ),
    meta: { title: "订单详情", needLogin: true },
  },
  {
    path: "/order_logistics/:orderId",
    name: "order_logistics",
    component: () =>
      import(
        /* webpackChunkName: "order_logistics" */ "@/views/Order/orderLogistics.vue"
      ),
    meta: { title: "订单物流", needLogin: true },
  },
  {
    path: "/goods_detail/:goodsId",
    name: "goods_detail",
    component: () =>
      import(
        /* webpackChunkName: "goods_detail" */ "@/views/Shop/goodsDetail.vue"
      ),
    meta: { title: "商品详情", needLogin: true },
  },
  {
    path: "/search",
    name: "search",
    component: () =>
      import(/* webpackChunkName: "search" */ "@/views/Search/search.vue"),
    meta: { title: "搜索药品", needLogin: true, keepAlive: true },
  },
  {
    path: "/goodclassify",
    name: "goodclassify",
    component: () =>
      import(
        /* webpackChunkName: "goodclassify" */ "@/views/goodClassify/index.vue"
      ),
    meta: { title: "药品分类", needLogin: true, keepAlive: true },
  },
  {
    path: "/medical-insurance",
    name: "medical-insurance",
    component: () =>
      import(
        /* webpackChunkName: "medical-insurance" */ "@/views/MedicalInsurance/index.vue"
      ),
    meta: { title: "医保复诊", needLogin: true },
  },
  {
    path: "/medical-insurance-miniapp",
    name: "medical-insurance-miniapp",
    component: () =>
      import(
        /* webpackChunkName: "medical-insurance" */ "@/views/MedicalInsurance/miniapp.vue"
      ),
    meta: { title: "医保复诊", needLogin: true },
  },
  {
    path: "/choose-records",
    name: "choose-records",
    component: () =>
      import(
        /* webpackChunkName: "choose-records" */ "@/views/Records/chooseRecords.vue"
      ),
    meta: { title: "选择就诊人", needLogin: true },
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/views/MedicalInsurance/about.vue"
      ),
    meta: { title: "医保复诊", needLogin: true },
  },
  {
    path: "/personalTnfoProcessingRules",
    name: "PersonalTnfoProcessingRules",
    component: () =>
      import(
        /* webpackChunkName: "iquiry_protocol" */ "@/views/IquiryProtocol/PersonalTnfoProcessingRules.vue"
      ),
    meta: { title: "在线问诊服务个人信息处理规则" },
  },
  {
    path: "/InformedConsent",
    name: "InformedConsent",
    component: () =>
      import(
        /* webpackChunkName: "iquiry_protocol" */ "@/views/IquiryProtocol/InformedConsent.vue"
      ),
    meta: { title: "互联网诊疗风险告知及知情同意书" },
  },
  {
    path: "*",
    name: "error",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/error/index.vue"),
    meta: { title: "404" },
  },
  {
    path: "/patientDetail",
    name: "PatientDetail",
    component: () =>
      import(
        /* webpackChunkName: "PatientDetail" */ "@/views/PatientDetail/index.vue"
      ),
    meta: { title: "患者详情" },
  },
];

const router = new VueRouter({
  mode: "history",
  // scrollBehavior: () => ({ y: 0 }), // 不建议添加在此处
  routes,
});

import { getLoginkey } from "@/utils/userInfo/loginkey";
router.beforeEach(async (to, from, next) => {
  if (to.meta?.title) {
    document.title = to.meta.title;
  }
  let url = to.fullPath.replace(/amp;|amp%3B/g, "");
  console.log(url);
  const queryData = getStrs(url);
  console.log(queryData);
  if (queryData && queryData.platformGrantCode) {
    await store.dispatch("user/loginThird", queryData.platformGrantCode);
    queryData.platformGrantCode = "";
    next({ path: to.path, query: queryData, replace: true });
  } else if (queryData && queryData.login_key_expire) {
    await store.dispatch("user/loginYXMini", queryData.login_key_expire);
    queryData.login_key_expire = "";
    next({ path: to.path, query: queryData, replace: true });
  } else if (to.meta?.needLogin) {
    if (getLoginkey()) {
      next();
    } else {
      Dialog.confirm({
        message: "您尚未登录，请先登录。",
        confirmButtonText: "去登录",
        confirmButtonColor: "#222",
        cancelButtonText: "取消",
        className: "van-dialog_consent",
      })
        .then(() => {
          router.push({
            path: "/login",
            query: { replace: to.fullPath },
          });
        })
        .catch(() => {
          if (isWxMini()) {
            window.wx.miniProgram.navigateBack({ delta: 3 });
          } else {
            next(from);
          }
        });
    }
  } else {
    next();
  }
});

export default router;
