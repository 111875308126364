/* eslint-disable no-unused-vars */
import request from "@/base/index";
import { hospital_id } from "@/appConfig.js";
import { addLoginkey } from "@/utils/userInfo/loginkey_add.js";
export default {
  namespaced: true,
  state: {
    isLogin: false,
    loginInfo: {},
    user_id: "",
    login_token: "",
  },
  getters: {
    mobile(state) {
      return state?.loginInfo?.userInfo?.mobile || "未登录";
    },
  },
  mutations: {
    saveLoginInfo(state, payload) {
      //保存用户信息
      addLoginkey(payload.loginkey);
      localStorage.setItem(payload.loginkey, JSON.stringify(payload));
    },
    //回显登录信息
    echoLoginInfoData(state, payload) {
      state.loginInfo = payload;
      state.login_token = payload.login_token;
      //这个要在下面
      state.isLogin = true;
      state.user_id = payload.userInfo.uid;
    },
    setState(state, payload) {
      const { key, value } = payload;
      state[key] = value;
    },
    loginOut(state, payload) {
      //清空用户信息
      state.loginInfo = {};
      state.login_token = "";
      //这个要在下面
      state.isLogin = false;
      state.user_id = "";
      addLoginkey("");
      localStorage.setItem(payload, "");
    },
  },
  actions: {
    login(context, payload) {
      return request
        .post(
          "api/guangzhou-pat/login/index",
          { mobile: payload.mobile, verify_code: payload.code, hospital_id },
          true
        )
        .then(({ code, msg, data }) => {
          if (code == 200) {
            context.commit("echoLoginInfoData", data);
            context.commit("saveLoginInfo", data);
          } else {
            context.commit("showMessage", msg, { root: true });
            context.commit("setState", { key: "isLogin", value: false });
          }
          return {
            code,
          };
        });
    },
    //推广平台登录
    loginThird(context, payload) {
      return new Promise((reslove) => {
        request
          .post("api/guangzhou-pat/login/dz", { grantCode: payload }, true)
          .then(({ code, msg, data }) => {
            if (code == 200) {
              context.commit("echoLoginInfoData", data);
              context.commit("saveLoginInfo", data);
              reslove(data);
            } else {
              context.commit("showMessage", msg, { root: true });
              context.commit("setState", { key: "isLogin", value: false });
              reslove("失败");
            }
          });
      });
    },
    //圆心健康登录
    loginYXMini(context, payload) {
      return new Promise((reslove) => {
        request
          .post(
            "api/guangzhou-pat/login/verification-login",
            { loginkey: payload },
            true
          )
          .then(({ code, msg, data }) => {
            if (code == 200) {
              console.log(data);
              const loginData = {
                loginkey: data.loginkey,
                login_token: data.login_token,
                userInfo: { ...data },
              };
              context.commit("echoLoginInfoData", loginData);
              context.commit("saveLoginInfo", loginData);
              reslove(loginData);
            } else {
              context.commit("showMessage", msg, { root: true });
              context.commit("setState", { key: "isLogin", value: false });
              reslove("失败");
            }
          });
      });
    },
    loginOut(context, payload) {
      context.commit("loginOut", payload);
    },
    sendCode(context, payload) {
      return request
        .get(
          "api/guangzhou-pat/login/send-code",
          { mobile: payload.mobile, hospital_id },
          true
        )
        .then(({ code, msg, data }) => {
          console.log(code, msg, data);
        });
    },
  },
};
