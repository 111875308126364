import tim from "tim";
import TIM from "tim-js-sdk-ws";
import { Dialog } from "vant";

let that = null;
let oneFlag = true;
//tim 事件中vue组件中的this
export function setThis(vueCom_this) {
  that = vueCom_this;
}
let isLogin = false;
export function loginTim() {
  if (null === that) {
    console.error("请先设置this的指向");
  } else if (!isLogin) {
    that.$store.dispatch("login"); //登录的action中有判断，不传参数就返回，所以此处无效
    isLogin = true;
  }
}

//重置
export function resetTim() {
  console.log("重置im 相关");
  that.$store.dispatch("logout");
  offTimListener();
  that = null;
  isLogin = false;
  oneFlag = true;
}

export function addTimListener() {
  console.log("绑定事件");
  if (!oneFlag) return;
  if (null === that) {
    console.error("请先设置this的指向");
  } else {
    initListener();
    oneFlag = false;
  }
}

export function offTimListener() {
  offListener();
}

function initListener() {
  // 登录成功后会触发 SDK_READY 事件，该事件触发后，可正常使用 SDK 接口
  tim.on(TIM.EVENT.SDK_READY, onReadyStateUpdate, that);
  // SDK NOT READT
  tim.on(TIM.EVENT.SDK_NOT_READY, onNoReady, that);
  // 被踢出
  tim.on(TIM.EVENT.KICKED_OUT, onKickOut);
  // SDK内部出错
  tim.on(TIM.EVENT.ERROR, onError);
  // 收到新消息
  tim.on(TIM.EVENT.MESSAGE_RECEIVED, onReceiveMessage);
  // 会话列表更新
  tim.on(TIM.EVENT.CONVERSATION_LIST_UPDATED, onUpdateConversationList);
  // 群组列表更新
  tim.on(TIM.EVENT.GROUP_LIST_UPDATED, onUpdateGroupList);
  // 网络监测
  tim.on(TIM.EVENT.NET_STATE_CHANGE, onNetStateChange);
  // 已读回执
  tim.on(TIM.EVENT.MESSAGE_READ_BY_PEER, onMessageReadByPeer);
}

//移除 tim 的事件
function offListener() {
  // 登录成功后会触发 SDK_READY 事件，该事件触发后，可正常使用 SDK 接口
  tim.off(TIM.EVENT.SDK_READY, onReadyStateUpdate, that);
  // SDK NOT READT
  tim.off(TIM.EVENT.SDK_NOT_READY, onNoReady, that);
  // 被踢出
  tim.off(TIM.EVENT.KICKED_OUT, onKickOut);
  // SDK内部出错
  tim.off(TIM.EVENT.ERROR, onError);
  // 收到新消息
  tim.off(TIM.EVENT.MESSAGE_RECEIVED, onReceiveMessage);
  // 会话列表更新
  tim.off(TIM.EVENT.CONVERSATION_LIST_UPDATED, onUpdateConversationList);
  // 群组列表更新
  tim.off(TIM.EVENT.GROUP_LIST_UPDATED, onUpdateGroupList);
  // 网络监测
  tim.off(TIM.EVENT.NET_STATE_CHANGE, onNetStateChange);
  // 已读回执
  tim.off(TIM.EVENT.MESSAGE_READ_BY_PEER, onMessageReadByPeer);
}

function onReceiveMessage({ data: messageList }) {
  const [e] = messageList;

  if (that.$store.getters.groupId == e.to) {
    e.isNew = true;
    that.$store.commit("pushCurrentMessageList", messageList);
    that.$bus.$emit("scroll-bottom");
  }
  const msg = JSON.parse(JSON.stringify(e));
  if (msg.payload.operationType == 5) {
    that.getevaluate && that.getevaluate();
  }
}

function onError({ data }) {
  if (data.message !== "Network Error") {
    this.$store.commit("showMessage", {
      message: data.message,
      type: "error",
    });
  }
  reloadPage("IM 错误，点击确认后刷新页面");
}
function onMessageReadByPeer() {}
function reloadPage(msg) {
  //提示重新连接
  Dialog.alert({
    title: "提示",
    message: msg,
    confirmButtonText: "刷新页面",
    showCancelButton: false,
    confirmButtonColor: "#00be8c",
  }).then(() => {
    window.location.reload();
  });
}
function onNoReady() {
  reloadPage();
}
function onReadyStateUpdate({ name }) {
  const isSDKReady = name === TIM.EVENT.SDK_READY ? true : false;
  that.$store.commit("toggleIsSDKReady", isSDKReady);
  if (isSDKReady) {
    tim
      .getMyProfile()
      .then(({ data }) => {
        that.$store.commit("updateCurrentUserProfile", data);
        that.$bus.$emit("login-sucess");
      })
      .catch((error) => {
        that.$store.commit("showMessage", {
          type: "error",
          message: error.message,
        });
      });
  }
}

function onNetStateChange() {
  //  网络状态发生改变（v2.5.0 起支持）。
  // event.name - TIM.EVENT.NET_STATE_CHANGE
  // event.data.state 当前网络状态，枚举值及说明如下：
  //     \- TIM.TYPES.NET_STATE_CONNECTED - 已接入网络
  //     \- TIM.TYPES.NET_STATE_CONNECTING - 连接中。很可能遇到网络抖动，SDK 在重试。接入侧可根据此状态提示“当前网络不稳定”或“连接中”
  //    \- TIM.TYPES.NET_STATE_DISCONNECTED - 未接入网络。接入侧可根据此状态提示“当前网络不可用”。SDK 仍会继续重试，若用户网络恢复，SDK 会自动同步消息
}
function onKickOut() {
  console.log("被踢出......");
  // 收到被踢下线通知
  // event.name - TIM.EVENT.KICKED_OUT
  // event.data.type - 被踢下线的原因，例如:
  //    - TIM.TYPES.KICKED_OUT_MULT_ACCOUNT 多实例登录被踢
  //    - TIM.TYPES.KICKED_OUT_MULT_DEVICE 多终端登录被踢
  //    - TIM.TYPES.KICKED_OUT_USERSIG_EXPIRED 签名过期被踢 （v2.4.0起支持）。
  that.$store.commit("toggleIsLogin", false);
  that.$store.commit("reset");
  reloadPage("IM 被踢下线，点击确认后刷新页面");
}
function onUpdateConversationList(event) {
  // 获得用户聊天会话
  console.log(event);
  that.$store.commit("updateConversationList", event.data);
}
function onUpdateGroupList(event) {
  console.log("群组列表更新........", event.data);
  //医生接诊---用户进群---此处有一个时间差，导致在im页无法正常聊天
  that.$store.dispatch("updateGroupList", event.data);
}

// ononline version
window.ononline = () => {
  if (!that) return;
  if (!that?.$store?.getters.isLogin || !that?.$store?.getters.isSDKReady) {
    //tim/index.vue 监听到了变化会处理
    loginTim();
  } else {
    try {
      let promise = tim.getGroupList();
      promise
        .then(function (imResponse) {
          console.log(imResponse.data.groupList); // 群组列表
        })
        .catch(function (imError) {
          console.warn("getGroupList error:", imError); // 获取群组列表失败的相关信息
        });
    } catch (e) {
      console.log("getGroupList error", e);
    }
  }
};
