import { imRequest } from "@/base/index";
import tim from "@/views/Chat/tim";
import { addTimListener } from "@/views/Chat/timEvent";
//腾讯代码，未改造
const user = {
  state: {
    currentUserProfile: {},
    isLogin: false,
    isSDKReady: false, // TIM SDK 是否 ready
    userSig: "",
    sdkAppID: 0,
  },
  getters: {
    userSig(state) {
      return state.userSig;
    },
    isSDKReady(state) {
      return state.isSDKReady;
    },
    timIsLogin(state) {
      return state.isLogin;
    },
  },
  mutations: {
    updateCurrentUserProfile(state, userProfile) {
      state.currentUserProfile = userProfile;
    },
    toggleIsLogin(state, isLogin) {
      state.isLogin = typeof isLogin === "undefined" ? !state.isLogin : isLogin;
    },
    toggleIsSDKReady(state, isSDKReady) {
      state.isSDKReady =
        typeof isSDKReady === "undefined" ? !state.isSDKReady : isSDKReady;
    },
    updateState(state, payload) {
      Object.assign(state, payload);
    },
    reset(state) {
      Object.assign(state, {
        currentUserProfile: {},
        isLogin: false,
        isSDKReady: false, // TIM SDK 是否 ready
      });
    },
    GET_USER_INFO(state, payload) {
      state.sdkAppID = payload.sdkAppID;
    },
    //----
    saveUserSig(state, payload) {
      state.userSig = payload;
      console.log("登录。。。。。。。。。。。。。。");
      if (payload) {
        addTimListener();
        // loginTim();
      }
    },
  },
  actions: {
    login(context, payload = {}) {
      if (!payload.uid) return;
      if (context.state.isLogin) return Promise.resolve();
      const userID = payload.uid + "_2";
      try {
        tim
          .login({
            userID,
            userSig: context.state.userSig,
          })
          .then(() => {
            context.commit("toggleIsLogin", true);
            context.commit({
              type: "GET_USER_INFO",
              // eslint-disable-next-line no-undef
              sdkAppID: ENV.sdkAppID,
            });
          })
          .catch((imError) => {
            if (imError.code === 2000) {
              console.error(
                imError.message + ", 请检查是否正确填写了 SDKAPPID"
              );
            } else {
              console.error(imError.message);
            }
          });
      } catch (err) {
        console.log("login error");
      }
    },
    logout(context) {
      try {
        if (context.state.isLogin) {
          // 若有当前会话，在退出登录时已读上报
          if (
            context.rootState.conversation.currentConversation.conversationID
          ) {
            tim.setMessageRead({
              conversationID:
                context.rootState.conversation.currentConversation
                  .conversationID,
            });
          }
          tim.logout().then(() => {
            context.commit("toggleIsLogin");
            // context.commit('stopComputeCurrent')
            context.commit("reset");
          });
        }
      } catch (e) {
        console.log("logout error");
      }
    },
    getusersig(context, payload) {
      const userSig = context.state.userSig;
      if (userSig) return Promise.resolve();
      const params = Object.assign({}, payload);
      return imRequest
        .post("newBpi/v1/member/user-sig", params, true)
        .then(({ code, data, msg }) => {
          if (200 == code) {
            // data.usersig
            context.commit("saveUserSig", data.sign);
          } else {
            context.commit("showMessage", msg, { root: true });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};

export default user;
