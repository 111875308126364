/**
 * @param {any} arg
 * @returns {Boolean}
 */
export function isNoEmpty(value) {
  return (
    value !== null &&
    value !== "null" &&
    value !== undefined &&
    value !== "undefined" &&
    value !== "" &&
    JSON.stringify(value) !== "{}" &&
    JSON.stringify(value) !== "[]"
  );
}

/**
 * @param {any} arg
 * @returns {Boolean}
 */
export function isEmpty(value) {
  return (
    value === null ||
    value === "null" ||
    value === undefined ||
    value === "undefined" ||
    value === "" ||
    JSON.stringify(value) === "{}" ||
    JSON.stringify(value) === "[]"
  );
}
