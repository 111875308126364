import Vue from "vue";
import Vuex from "vuex";
import { Toast } from "vant";
import user from "./user.js";
import hospitals from "./hospitals.js";
import doctor from "./doctor.js";
import interrogation from "./interrogation.js";
import iquiry from "./iquiry.js";
import consult from "./consult.js";
import timUser from "./tim/timUser";
import group from "./tim/group";
import conversation from "./tim/conversation";
import evaluate from "./evaluate";
import notice from "./notice";
import cart from "./cart";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {
    showMessage(state, payload = {}) {
      if (typeof payload === "string" && payload) {
        Toast(payload);
      } else if (typeof payload == "object" && payload.message) {
        Toast(payload.message);
      }
    },
  },
  actions: {},
  modules: {
    user,
    hospitals,
    doctor,
    interrogation,
    iquiry,
    consult,
    timUser,
    group,
    conversation,
    evaluate,
    notice,
    cart,
  },
});
