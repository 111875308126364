import { getCookie } from "@/utils/cookie/getCookie";
import { getQueryVariable } from "@/utils/url/getQueryVariable.js";
import { addLoginkey } from "./loginkey_add";
//获得用户登录信息
// login_key_expire：用户中心生成的用户信息
// loginkey: 本地开发环境，客户端通过login_key_expire存储的登录信息
// uc_login_key：APP端及测试环境
export function getLoginkey() {
  let login_key_expire = getQueryVariable("login_key_expire");
  if (login_key_expire) {
    addLoginkey(login_key_expire);
    sessionStorage.setItem("uc_login_key", login_key_expire);
  } else {
    login_key_expire =
      getCookie("uc_login_key") || sessionStorage.getItem("uc_login_key");
  }
  if (!login_key_expire) {
    login_key_expire = getCookie("login_key_expire") || getCookie("loginkey");
  }
  return login_key_expire?.split("_")[0] || "";
}
