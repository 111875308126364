import { CryptoJSUrl } from "@/cdnUrlConfig";
import { loadScript } from "@/utils/loadScript";
/**
 * AES加密
 */
export async function aesEncrypt(str1, key1) {
  if (!window.CryptoJS) await loadScript(CryptoJSUrl);
  let cryptoJs = window.CryptoJS;
  let key = cryptoJs.enc.Utf8.parse(key1);
  let str = cryptoJs.enc.Utf8.parse(str1);
  let encrypted = cryptoJs.AES.encrypt(str, key, { mode: cryptoJs.mode.ECB });
  return encrypted.toString();
}

/**
 * AES解密
 */
export async function aesDecrypt(str1, key1) {
  if (!window.CryptoJS) await loadScript(CryptoJSUrl);
  let cryptoJs = window.CryptoJS;
  let str = decodeURIComponent(str1);
  let key = cryptoJs.enc.Utf8.parse(key1);
  let decrypt = cryptoJs.AES.decrypt(str, key, { mode: cryptoJs.mode.ECB });
  return cryptoJs.enc.Utf8.stringify(decrypt).toString();
}
