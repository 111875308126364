import config from "@/appConfig.js";
import { newImApiConfig } from "@/appConfig.js";

import { getRandomStr } from "@/utils/string/getRandomStr.js";
import { loadScript } from "@/utils/loadScript";
import { CryptoJSUrl } from "@/cdnUrlConfig";
import { isNoEmpty } from "@/utils/validator/isEmptyOrNot.js";

/**
 * 接口加密处理类
 * 对互联网医院请求参数进行签名
 */
export async function signParams(params = {}) {
  // let { appkey, appid, keyName = "key", ...otherParam } = params;
  let { appid, ...otherParam } = params;
  // if (!appkey) {
  //   appkey = config.appkey;
  // }
  if (!appid) {
    appid = config.appid;
  }
  var time = Date.parse(new Date()) / 1000;
  let base_params = {
    channel_key: config.channel_key,
    appid,
    time: time,
    os: config.os,
    version: config.version,
    noncestr: getRandomStr(6),
  };
  var tempParams = Object.assign(base_params, otherParam);
  var sort_params = Object.keys(tempParams).sort();
  var str = "";
  var k = 0;
  for (k in sort_params) {
    var value = tempParams[sort_params[k]];
    if (Array.isArray(value)) {
      value = JSON.stringify(value);
    } else if (typeof value == "object") {
      value = JSON.stringify(value);
    }

    str =
      str +
      sort_params[k] +
      "=" +
      value +
      (k == sort_params.length - 1 ? "" : "&");
  }
  // str = `${str}${keyName}=${appkey}`;
  //此处可以优化
  if (!window.CryptoJS) await loadScript(CryptoJSUrl);
  var sign = window.CryptoJS.MD5(str, { asString: true })
    .toString()
    .toUpperCase();
  return Object.assign(tempParams, { sign: sign });
}

export async function imSignParams(params = {}) {
  let { appkey, appid, ...otherParam } = params;
  if (!appkey) {
    appkey = newImApiConfig.appkey;
  }
  if (!appid) {
    appid = newImApiConfig.appid;
  }
  var time = Date.parse(new Date()) / 1000;
  let base_params = {
    channel_key: newImApiConfig.channel_key,
    appid,
    time: time,
    os: config.os,
    version: config.version,
    noncestr: getRandomStr(6),
  };
  var tempParams = Object.assign(base_params, otherParam);
  var sort_params = Object.keys(tempParams).sort();
  var str = "";
  var k = 0;
  for (k in sort_params) {
    var value = tempParams[sort_params[k]];
    if (Array.isArray(value)) {
      value = JSON.stringify(value);
    } else if (typeof value == "object") {
      value = JSON.stringify(value);
    }

    if (isNoEmpty(value)) {
      str =
        str +
        sort_params[k] +
        "=" +
        value +
        (k == sort_params.length - 1 ? "" : "&");
    }
  }
  // str = `${str}&${keyName}=${appkey}`;
  //此处可以优化
  if (!window.CryptoJS) await loadScript(CryptoJSUrl);
  var sign = window.CryptoJS.MD5(str, { asString: true })
    .toString()
    .toUpperCase();
  return Object.assign(tempParams, { sign: sign });
}
