import proxyConfig from "@/proxyConfig";
let cacheUrl = {};
// url type: string
// 示例：v6/web/login/active
export function getBaseUrl(url) {
  if (cacheUrl[url]) return cacheUrl[url];
  if (typeof url === "string") {
    let urlArr = url.split("/");
    while (!urlArr[0]) {
      urlArr = urlArr.slice(1); //以/或//开头，例如/api/goods/detail
    }
    urlArr[0] = proxyConfig[urlArr[0]] || urlArr[0]; //开发环境不变
    let fullUrl = urlArr.join("/");
    cacheUrl[url] = fullUrl;
    return fullUrl;
  }
  return url;
}
/**
 * api对应的认证接口
 * @param {活动} 正常请求的url
 * @returns 返回认证接口url
 */
export function getBaseApiInfoUrl(url) {
  let urlArr = url.split("/");
  while (!urlArr[0]) {
    urlArr = urlArr.slice(1); //以/或//开头，例如/api/goods/detail
  }
  return proxyConfig[urlArr[0] + "Info"];
}
