import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// 全局引入按需引入UI库 vant
import "@/plugins/vant";
import { addPolyfill } from "./polyfill";
import {
  List,
  Popup,
  Switch,
  Search,
  Radio,
  RadioGroup,
  Checkbox,
  CheckboxGroup,
  Dialog,
  PasswordInput,
  NumberKeyboard,
  Overlay,
  Image as VanImage,
  Icon,
  Button,
  Cell,
  CellGroup,
  Form,
  Field,
  Tab,
  Tabs,
  Uploader,
  Empty,
  Slider,
  NavBar,
  Skeleton,
  Lazyload,
  ActionSheet,
  IndexBar,
  IndexAnchor,
  Swipe,
  SwipeItem,
  Loading,
  ImagePreview,
  NoticeBar,
  Cascader,
  Steps,
  Step,
  Stepper,
} from "vant";
Vue.use(List)
  .use(Popup)
  .use(Switch)
  .use(Search)
  .use(Dialog)
  .use(PasswordInput)
  .use(NumberKeyboard)
  .use(Cell)
  .use(Overlay)
  .use(CellGroup)
  .use(Radio)
  .use(Icon)
  .use(RadioGroup)
  .use(Form)
  .use(Tab)
  .use(Tabs)
  .use(Empty)
  .use(Button)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Uploader)
  .use(Slider)
  .use(Field)
  .use(NavBar)
  .use(Skeleton)
  .use(VanImage)
  .use(ActionSheet)
  .use(Swipe)
  .use(SwipeItem)
  .use(Lazyload)
  .use(IndexBar)
  .use(IndexAnchor)
  .use(Loading)
  .use(ImagePreview)
  .use(NoticeBar)
  .use(Cascader)
  .use(Steps)
  .use(Step)
  .use(Stepper);

Vue.config.productionTip = false;

import api from "@/base/install";
Vue.use(api);

import { $bus } from "@/utils/bus";

Vue.prototype.$bus = $bus;

import * as filters from "./filters";
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

//eslint-disable-next-line no-undef
// if (1 === VUE_APP_IS_SHOW_VCONSOLE) {
//   import(/* webpackChunkName: "vconsole" */ "vconsole").then((vconsole) => {
//     new vconsole.default();
//   });
// }

addPolyfill(() => {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
});

//vue 捕获异常
Vue.config.errorHandler = function (err, vm, info) {
  console.log(err, vm, info);
  vm.onError && vm.onError(err);
  // Toast("服务异常，请稍后再试");
  // 处理错误
  // `info` 是 Vue 特定的错误信息，比如错误所在的生命周期钩子
};

//捕获 promise 异常
window.addEventListener("unhandledrejection", (event) => {
  console.log("捕获promise 异常", event);
});

//捕获 onerror 异常
window.addEventListener("error", (event) => {
  console.log("捕获error 异常", event);
});
